<!-- 入园凭证 -->
<template>
    <div class="certificate">
        <div class="certificate-swiper">
            <div class="certificate-line"></div>
            <div class="certificate-title">
                <span>{{info.order.ticketType}}</span>
                <span>剩余{{info.order.ticketNumber}}张</span>
            </div>
            <div class="certificate-ewm">
                <van-swipe :show-indicators="false" @change="onChange">
                    <van-swipe-item 
                        v-for="(item,index) in qrCodeList" 
                        :key="index" class="qrcode-box" 
                        :id='"qrcode" + index'
                        :loop="false" 
                        ref="qrcode">
                        <div v-if="item.status!=1">
                            <div  class="ewm-invalid"></div>
                        </div>
                    </van-swipe-item>                
                </van-swipe>
                <div :class="{'custom-ticketId':'custom-ticketId','invalid':isInvalid}" v-show="qrCodeList.length!='0'">
                    取票号{{ticketId}}
                </div>
                <div class="custom-index">
                   <span>{{ Index + 1 }}</span> 
                </div>
            </div>          
        </div>
         <!-- 票务信息 -->
        <div class="certificate-introduce">
            <div class="certificate-introduce-title">票务信息</div>
            <div class="certificate-introduce-li"><span>购票人姓名：</span><span>{{info.order.consignee}}</span></div>
            <div class="certificate-introduce-li"><span>手机号：</span><span>{{info.order.mobile}}</span></div>
            <div class="certificate-introduce-li"><span>购票数量：</span><span>{{info.order.ticketNumber}}</span></div>
            <div class="certificate-introduce-li"><span>金额：</span><span>{{info.order.orderPrice}}</span></div>
            <div class="certificate-introduce-li"><span>游玩日期：</span><span>{{info.order.ticketDate.replace('00:00:00','')}}</span></div>
            <div class="certificate-introduce-li"><span>下单时间：</span><span>{{info.order.orderDate}}</span></div>
            <div class="certificate-introduce-li" style="color:#00A5FF;"><span>有效期：</span><span>{{info.order.ticketDate.replace('00:00:00','')}} 8:00-16:00</span></div>
            <div class="certificate-Prompt-line"></div>
            <div class="certificate-Prompt">
                <div class="certificate-Prompt-title">温馨提示</div>
                <div class="certificate-Prompt-text">
                    <p>1、请不要将电子票随意分享给他人，以免被盗用</p>
                    <p>2、入园时，请提前打开电子票，调亮手机屏幕，扫描二维码检票入园，1人1票</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swipe, SwipeItem } from 'vant';
import QRCode from "qrcodejs2";
export default {
  data () {
    return {
        info:{
            order:{
                ticketDate:'00:00:00'
            },
        },
        qrCodeList:[],
        ticketId:'',
        Index: 0,
        isInvalid:false,
    };
  },
  components: {
      [Swipe.name]: Swipe,
      [SwipeItem.name]: SwipeItem,
  },
  mounted() { 
    this.init();
  },
  methods: { 
    init() {
        let Bid = this.$route.query.id;
        // 异步更新数据  
        this.$ajax.get('https://wx.tslbrsr.com/tb/order/codesList', {
            params:{
                orderSn: Bid
            }
        })
        .then(res=> {
            // 成功
            console.log(res.data.data);
            this.info = res.data.data;
            this.qrCodeList =  res.data.data.list.list;
            this.$nextTick(() => {
                this.qrcode(this.qrCodeList);
                this.ticketId = this.qrCodeList[0].code;
            })
        })
        .catch( (error)=> {
            console.log(error);
        });      
    },
    onChange(index) {
        this.ticketId = this.qrCodeList[index].code;
        if(this.qrCodeList[index].status!=1){
            this.isInvalid =true;
        }else{
            this.isInvalid =false;
        }
        this.Index = index;
    },
    qrcode(qrCodeList) {
        qrCodeList.forEach((item,index) => {
            if(item.status==1){
                let qrcode = new QRCode("qrcode"+index, {
                    width: 140, // 二维码宽度，单位像素
                    height: 140, // 二维码高度，单位像素
                    text: item.code // 设置二维码内容或跳转地址
                });
            }
            
        });
    },
  }
}

</script>
<style scoped>
.certificate{
    background-color:#00A5FF;
    box-sizing: border-box;
    padding:0.53rem 0.37rem 0.667rem 0.37rem;
}
.certificate-swiper{
    width: 100%;  
    height:7.88rem ;
    background: #fff;
    border-radius: 4px;
    position: relative;
}
.certificate-swiper::after{
    content: "";
    width: 0.426rem;
    height: 0.426rem;
    background: #00A5FF;
    border-radius:50%;
    position: absolute;
    left: -0.213rem;
    top: 1.2rem;
}
.certificate-swiper::before{
    content: "";
    width: 0.426rem;
    height: 0.426rem;
    background: #00A5FF;
    border-radius:50%;
    position: absolute;
    right: -0.213rem;
    top: 1.2rem;
}
.certificate-line{
    width: 8.37rem;
    height: 0px;
    position: absolute;
    top:1.4rem ;
    left: 0.387rem;
    border-bottom:1px dashed #EEEEEE;
}

.certificate-title{
    padding-top:0.37rem ;
    height: 1.41rem;
    box-sizing: border-box;
}
.certificate-title span:first-child{
    font-size: 0.48rem;
    color:#333;
    line-height: 0.69rem;
    margin-left:0.53rem;
    float: left;
}
.certificate-title span:last-child{
    font-size: 0.37rem;
    color:#333;
    line-height: 0.53rem;
    position: absolute;
    right: 0.52rem;
    top: 0.44rem;   
    color: #00A5FF;
}
.van-swipe-item{
    display: flex;
    display: -webkit-flex;
    align-items:center;
    justify-content:center;
}
.certificate-ewm{   
    height: 6.45rem;  
    box-sizing: border-box;
    position: relative;
    padding-top:0.6rem ;
}
.custom-ticketId{
    font-size: 0.37rem;
    color:#333;
    text-align: center;
    position: absolute;
    bottom:1.33rem ;
    width: 100%;
}
.custom-ticketId{
    font-size: 0.37rem;
    color:#333;
    text-align: center;
    position: absolute;
    bottom:1.33rem ;
    width: 100%;
}
.custom-index{
    font-size: 0.27rem;
    width: 100%;
    height:0.53rem ;
    display: flex;
    display: -webkit-flex;
    align-items:center;
    justify-content:center;
    position: absolute;
    left: 0;
    bottom:0.55rem;
}
.custom-index span{
    width: 0.53rem;
    height: 0.53rem;
    line-height: 0.53rem;
    border-radius: 50%;
    text-align: center;
    background: #00A5FF;
    color: #fff;
    transition: all 0.5s;
}
.invalid{
    color: #888888;
    text-decoration: line-through;
}
.ewm-invalid{
    width: 150px;
    height: 150px;
    background: url("../../static/in-ewm.jpg") no-repeat 0 0;
    background-size:100% 100%; 
    position: relative;
}
.ewm-invalid::before{
    content: '';
    background: url('../../static/invalid.png') no-repeat 0 0;
    background-size:100% 100%; 
    /* background: red; */
    width: 62px;
    height: 62px;
    position: absolute;
    right:-20px;
    top:0;
    z-index: 10;
}
.certificate-introduce{
    width: 100%;
    height:10.4rem ;
    background: #fff;
    border-radius: 0.10667rem;
    margin-top:0.26rem;
    padding: 0.48rem 0.53rem 0 0.53rem;
    box-sizing: border-box;
}
.certificate-introduce-title{
    font-size: 0.48rem;
    color: #222222;
    line-height: 0.693rem;
    font-weight: bold;
    margin-bottom:0.293rem;
}
.certificate-introduce-li{
    font-size: 0.346rem;
    color: #888;
    margin-bottom:0.293rem;
}
.certificate-Prompt-title{
    font-size:0.48rem;
    color: #222; 
    margin-bottom:0.26rem;
    font-weight: bold;  
}
.certificate-Prompt-text{
    font-size: 0.32rem;
    color: #888;
}
.certificate-Prompt-text p{
    margin: 0;
}
.certificate-Prompt-line{
    width: 100%;
    height: 1px;
    background: #EEEEEE;
    margin: 0.48rem 0;    
}


</style>